import React, { useContext, useEffect, createRef } from "react";
import GlobalContext from "../context/GlobalContext";
import { Button, Section, Text } from "../components/Core";
import { navigate } from "gatsby"
import { Container, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { Helmet } from 'react-helmet';
import successAnimation from "./../assets/animations/success-banner.json"
import successAnimationMobile from "./../assets/animations/success-banner-mobile.json"
import rafBanner from "./../assets/image/rebrand/png/raf-banner.png"
import { isMobile } from "react-device-detect";

import lottie from "lottie-web"

const successAnimationContainer = createRef()

const RafBanner = styled.img`
    position: absolute;
    bottom: -50px;
    right: 0;
    width: 200px;
    @media (max-width: 768px) { 
        position: relative;
        bottom: -80px;
        right: -60px;
        width: 184px;
    }
`

const StyledText = styled(Text)`
  @media (max-width: 1320px) { 
    font-size: 1.3rem !important;
  }
`

const SuccessOrderPage = () => {
    const gContext = useContext(GlobalContext);
    const order_id = gContext.goGetRegistrationData()?.orderId;
    const order_value = gContext.goGetRegistrationData()?.totalPrice;

    useEffect(() => {
        gContext.hideFullScreenLoader();
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0)
        }

        const anim1 = lottie.loadAnimation({
            container: successAnimationContainer.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: isMobile ? successAnimationMobile : successAnimation
          });
    }, [])

    return (
        <>
            <Helmet>
                <script src={`https://secure.adnxs.com/px?id=1716401&seg=34711278&other=${order_value}&order_id=${order_id}&t=2`} type="text/javascript"></script>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <Section className="green-bg text-center pt-0 mt-0 pb-xs-0">
                <Container className="position-relative">
                    {
                        (gContext.goGetRegistrationData()?.skipBooking || gContext.goGetRegistrationData()?.noSlotsAvailable) ?
                            <Row className="justify-content-center">
                                <Col lg={8} md={10} sm={8}>
                                    <div
                                        id="animation-container"
                                        ref={successAnimationContainer}
                                    />
                                    <StyledText
                                        color="dark"
                                        fontWeight={700}
                                        fontSize="1.8rem"
                                        lineHeight="1.4"
                                        marginTop="1rem"
                                    >
                                    <b>
                                        Order placed, <br />now pop the kettle on and look out for email updates.
                                    </b>
                                    </StyledText>
                                    <Button
                                        onClick={() => {
                                            localStorage.removeItem('registrationData');
                                            gContext.goResetRegistrationData();
                                            if (typeof window !== 'undefined') { 
                                                window.location.href = 'https://youfibre.aklamio.com/?source=website_order_confirmation_cta';
                                            }
                                        }}
                                        className="mt-50 blue-bg"
                                    >Refer a Friend</Button>
                                </Col>
                            </Row>
                            :
                            <Row className="justify-content-center">
                                <Col lg={8} md={10} sm={8}>
                                    <div
                                        id="animation-container"
                                        ref={successAnimationContainer}
                                    />
                                    <StyledText
                                        color="dark"
                                        fontWeight={700}
                                        fontSize="1.8rem"
                                        lineHeight="1.4"
                                        marginTop="1rem"
                                    >
                                    <b>
                                        Order placed, installation scheduled, <br />now pop the kettle on and look out for email updates.
                                    </b>
                                    </StyledText>
                                    <Button
                                        onClick={() => {
                                            localStorage.removeItem('registrationData');
                                            gContext.goResetRegistrationData();
                                            if (typeof window !== 'undefined') { 
                                                window.location.href = 'https://youfibre.aklamio.com/?source=website_order_confirmation_cta';
                                            }
                                        }}
                                        className="mt-50 blue-bg medium-button"
                                    >Refer a Friend</Button>
                                </Col>
                            </Row>
                    }
                    <RafBanner
                        className="cursor-pointer"
                        src={rafBanner}
                        onClick={() => {
                        localStorage.removeItem('registrationData');
                        gContext.goResetRegistrationData();
                        if (typeof window !== 'undefined') { 
                            window.location.href = 'https://youfibre.aklamio.com/?source=website_order_confirmation_cta';
                        }
                    }}/>
                </Container>
            </Section>
        </>
    )
}

export default SuccessOrderPage